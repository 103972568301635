import "./ProcessPage.css";
import HoodieFlat1 from "../../assets/Hoodie Flat 1.png";
import HoodieForm1 from "../../assets/Hoodie Form 1.png";
import JacketCloseUp from "../../assets/Jacket Close Up.png";
import CrewneckFlat1 from "../../assets/Crewneck Flat 1.png";
import HoodieForm2 from "../../assets/Hoodie Form 2.png";
import CarpenterPantsFlat from "../../assets/Carpenter Pants Flat.png";
import DarkPantsCloseUp from "../../assets/Dark Pants Close Up.png";
import { Link } from "react-router-dom";

const ProcessPage = () => {
  const processSections = {
    "Fabric Procurement": {
      description:
        "We are a fully vertical operation, meaning we have complete control over obtaining the fabric we use. Our factory purchases raw ecru yarn from spinning mills and physically knits and dyes that yarn into the fabric that becomes our garments. This allows us to create fabric at any desired color, weight, grain, or style in the lowest possible quantities. Whether it’s heavyweight cotton fleece, lightweight cotton jersey, midweight french terry, or thermal pique cotton, our factory has experience creating every combination. We’re also happy to use any fabric you already have sourced.",
      image: HoodieFlat1,
      mobileImage: CarpenterPantsFlat,
    },
    "Dyeing Process": {
      description:
        "Our meticulous dyeing process involves a series of rigorous stages to guarantee precise color matching and finishing. Because we are working with our own undyed ecru yarn, we have absolute control over each step of the process. The ability to 1:1 match any TPX color code at these quantities is an invaluable resource that is extremely difficult to find elsewhere. After our fabric is dyed and washed, we perform what’s known as “finishing” to control fabric shrinkage and achieve the desired GSM and width. The fabric is then ready to be cut and sewn into a garment.",
      image: HoodieForm1,
      mobileImage: HoodieFlat1,
    },
    Sampling: {
      description:
        "We understand the significance of receiving samples that meet your quality standards. Our pattern-making department is able to follow any cut pattern, sewing garments to your exact specifications. We have developed stock molds for your use but can follow any custom pattern you have. We even accept physical reference samples to be sent directly to our factory for grading. Full-scale screen print and embroidery services are available for whichever branding options you prefer. Sample lead times vary depending on prototype requirements but typically take 7-10 days. Once you receive the samples in-hand, you will have the option to send us any revisions and go through another round of sampling or approve the samples to begin production.",
      image: JacketCloseUp,
      mobileImage: DarkPantsCloseUp,
    },
    Production: {
      description: (
        <span>
          Throughout the production process we will be providing intermittent updates as your order progresses. We are in constant communication with our factories and uphold same-day response rates when answering any questions you may have. Lead times differ between each order, but we will give you our most accurate estimate after discussing the details while the order is being placed. After orders are sewn, garments will be packaged to your specific requirements. Your order will then be shipped to you via air or sea. We work closely with various port forwarding facilities in order to ensure you are getting the absolute best price when it comes to overseas shipping in bulk. To begin your order or ask us any questions, please reach out via our <Link to="/contact">Contact Page</Link>
        </span>
      ),
      image: CrewneckFlat1,
      mobileImage: CrewneckFlat1,
    },
  };

  const renderProcessSections = () => {
    // render either the mobile or desktop image based on screen size
    const renderSectionImage = (
      sectionData: { image: string; mobileImage: string },
      alt: string,
      index: number
    ) => {
      return (
        <div className="process-image-wrapper">
          <img
            src={
              window.innerWidth > 1000
                ? sectionData.image
                : sectionData.mobileImage
            }
            alt={alt}
            className={`process-image-${index} `}
          />
        </div>
      );
    };
    return (
      <div className="process-section-wrapper">
        {Object.entries(processSections).map((data: any, index: number) => {
          if (index % 2 === 0)
            return (
              <div className="process-section">
                <section
                  className={`process-section-text ${
                    index % 2 === 0 ? "process-text-left" : "process-text-right"
                  }`}
                >
                  <span className="process-section-title">{data[0]}</span>
                  <span className="process-section-description">
                    {data[1].description}
                  </span>
                </section>
                <section className="process-section-image">
                  {renderSectionImage(data[1], data[0], index)}
                </section>
              </div>
            );
          else
            return (
              <div className="process-section">
                <section className="process-section-image">
                  {renderSectionImage(data[1], data[0], index)}
                  {index === 1 && window.innerWidth > 1000 ? (
                    <img
                      className="process-image-overlay"
                      src={HoodieForm2}
                      alt={data[0]}
                    />
                  ) : null}
                </section>
                <section className="process-section-text">
                  <span className="process-section-title">{data[0]}</span>
                  <span className="process-section-description">
                    {data[1].description}
                  </span>
                </section>
              </div>
            );
        })}
      </div>
    );
  };

  return (
    <div className="process-page-wrapper page-wrapper">
      <div className="process-content-wrapper">{renderProcessSections()}</div>
    </div>
  );
};

export default ProcessPage;
