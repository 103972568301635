import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';
import { AppDataContext } from './context/AppDataContext';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import CollagePage from './pages/CollagePage/CollagePage';
import LandingPage from './pages/LandingPage/LandingPage';
import ProcessPage from './pages/ProcessPage/ProcessPage';
import Ruler from './assets/ruler.png';

function App() {

  const [navigationPosition, setNavigationPosition] = useState<
    "top-right" | "top-left" | "bottom-right" | "bottom-left"
  >("bottom-right");
  useEffect(() => {
    console.log(window.location) 
  }, []);

  return (
    <AppDataContext.Provider value={{navigationPosition, setNavigationPosition}}>
      <BrowserRouter>
        <Navigation/>
        <Routes>
          <Route path='/' element={<LandingPage/>}/>
          <Route path='/contact' element={<ContactPage/>}/>
          <Route path='/collage' element={<CollagePage/>}/>
          <Route path='/gallery' element={<GalleryPage/>}/>
          <Route path='/about' element={<AboutPage/>}/>
          <Route path='/process' element={<ProcessPage/>}/>
        </Routes>
      </BrowserRouter>
      <img 
        alt="ruler"
        className={`ruler-banner ${window.location.pathname.substring(1)}`}
        src={Ruler}
      />
    </AppDataContext.Provider>
  );
}

export default App;
