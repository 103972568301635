import "./CollagePage.css";
import { useEffect, useState } from "react";
// import LazyImage from "../../components/LazyImage/LazyImage";

const CollagePage = () => {

  const [activeImage, setActiveImage] = useState(-1);
  const [imagePositions, setImagePositions] = useState<any[]>([]);

  useEffect(() => {
    const imagePositions = [];
    for (let i = 0; i < 79; i++) {
      const img = new Image();
      img.src = require(`../../assets/collage/collage${i+1}.jpg`)
      const randomX = Math.floor(Math.random() * 200) - 20;
      const randomY = Math.floor(Math.random() * 200) - 20;
      imagePositions.push({
        src: img.src,
        x: randomX, 
        y: randomY
      });
    }
    setImagePositions(imagePositions); 
  }, []);

  const renderImages = () => {
    return (
      <div className="images-wrapper">
        {imagePositions.map((image: {src: string, x: number, y: number}, index: number) => {
          const {x: randomX, y: randomY, src} = image;
          const style = window.innerWidth > 1000 
            ? {transform: `translate(${randomX}px, ${randomY}px)`}
            : {};
          return (
            <img
              src={src}
              className={`collage${index+1} collage-image`}
              onClick={() => setActiveImage(index)}
              alt={`collage-${index+1}`}
              style={style}
              key={index}
            />
          )
        })}
      </div>
    )
  }

  const renderActiveImage = () => {
    if (activeImage === -1)
      return null;
    else
      return (
        <div 
          className="active-image-wrapper"
          onClick={() => setActiveImage(-1)}
        >
          <img
            alt={`collage-${activeImage+1}`}
            className={`collage${activeImage+1} active-image`}
            src={imagePositions[activeImage].src}
          />
        </div>
      )
  }

  return (
    <div className="collage-page-wrapper page-wrapper">
      <span className="page-title">Collage</span>
      {renderActiveImage()}
      {renderImages()}
    </div>
  )
}

export default CollagePage;