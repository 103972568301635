import "./LandingPage.css";
import logo from '../../assets/logo.png';
import Cardigan1 from '../../assets/cardigan form 1.png';
import Cardigan2 from '../../assets/cardigan flat 2.png';
import CardiganFlat from '../../assets/Cardigan Flat 1.png';
import Crewneck from '../../assets/Crewneck Flat 1.png';
import HoodieForm1 from '../../assets/Hoodie Form 1.png';
import CanvasJacket from '../../assets/canvas jacket 1.png';
import TortoiseShell from '../../assets/Tortoise Shell Button.png';
import ZipperCloseUp from '../../assets/Zipper Close Up.png';

const LandingPage = () => {

  const renderLogo = () => {
    return (
      <img 
        src={logo}
        className="app-logo"
        alt="cain and abel"
      />
    )
  }

  const renderImages = () => {
    return (
      <div className="images-wrapper">
        <img 
          alt="cardigan form"
          className="cardigan-form"
          src={Cardigan1}
        />
        <img 
          alt="crewneck flat 1"
          className="crewneck-flat"
          src={Crewneck}
        />
        <img 
          alt="crewneck flat 1"
          className="hoodie-form"
          src={HoodieForm1}
        />
        <img 
          alt="cardigan flat 2"
          className="cardigan-flat-2"
          src={Cardigan2}
        />
        <img 
          alt="canvas jacket 1"
          className="canvas-jacket-1"
          src={CanvasJacket}
        />
        <img 
          alt="tortoise shell button"
          className="tortoise-shell-button"
          src={TortoiseShell}
        />
        <img 
          alt="cardigan flat 1"
          className="cardigan-flat-1"
          src={CardiganFlat}
        />
        <img 
          alt="zipper close up"
          className="zipper-close-up"
          src={ZipperCloseUp}
        />
      </div>
    )
  }

  return (
    <div className="landing-page-wrapper page-wrapper">
      {renderImages()}
      {renderLogo()}
    </div>
  )
}

export default LandingPage;