import "./GalleryPage.css";
import { useEffect, useState } from "react";
// import LazyImage from "../../components/LazyImage/LazyImage";

const GalleryPage = () => {

  const [activeImage, setActiveImage] = useState(-1);

  useEffect(() => {
    // add onclick event listener to all images
    const images = document.getElementsByClassName("gallery-image");
    for (let i = 0; i < images.length; i++) {
      images[i].addEventListener("click", () => {
        setActiveImage(i);
      })
    }
  }, []);

  // place all of the images in the gallery
  const renderImages = () => {
    return (
      <div className="images-wrapper">
        <img
          src={require("../../assets/gallery/1.png")}
          className="gallery-1 gallery-image"
          alt="gallery-1"
        />
        <img
          src={require("../../assets/gallery/2.png")}
          className="gallery-2 gallery-image"
          alt="gallery-2"
        />
        <img
          src={require("../../assets/gallery/3.gif")}
          className="gallery-3 gallery-image"
          alt="gallery-3"
        />
        <img
          src={require("../../assets/gallery/4.png")}
          className="gallery-4 gallery-image"
          alt="gallery-4"
        />
        <img
          src={require("../../assets/gallery/5.png")}
          className="gallery-5 gallery-image"
          alt="gallery-5"
        />
        <img
          src={require("../../assets/gallery/6.jpg")}
          className="gallery-6 gallery-image"
          alt="gallery-6"
        />
        <img
          src={require("../../assets/gallery/7.png")}
          className="gallery-7 gallery-image"
          alt="gallery-7"
        />
        <img
          src={require("../../assets/gallery/8.png")}
          className="gallery-8 gallery-image"
          alt="gallery-8"
        />
        <img
          src={require("../../assets/gallery/9.jpg")}
          className="gallery-9 gallery-image"
          alt="gallery-9"
        />
        <img
          src={require("../../assets/gallery/10.gif")}
          className="gallery-10 gallery-image"
          alt="gallery-10"
        />
        <img
          src={require("../../assets/gallery/11.png")}
          className="gallery-11 gallery-image"
          alt="gallery-11"
        />
        <img
          src={require("../../assets/gallery/12.png")}
          className="gallery-12 gallery-image"
          alt="gallery-12"
        />
        <img
          src={require("../../assets/gallery/13.jpg")}
          className="gallery-13 gallery-image"
          alt="gallery-13"
        />
        <img
          src={require("../../assets/gallery/14.gif")}
          className="gallery-14 gallery-image"
          alt="gallery-14"
        />
        <img
          src={require("../../assets/gallery/15.jpg")}
          className="gallery-15 gallery-image"
          alt="gallery-15"
        />
        <img
          src={require("../../assets/gallery/16.jpg")}
          className="gallery-16 gallery-image"
          alt="gallery-16"
        />
        <img
          src={require("../../assets/gallery/17.jpg")}
          className="gallery-17 gallery-image"
          alt="gallery-17"
        />
        <img
          src={require("../../assets/gallery/18.png")}
          className="gallery-18 gallery-image"
          alt="gallery-18"
        />
        <img
          src={require("../../assets/gallery/19.jpg")}
          className="gallery-19 gallery-image"
          alt="gallery-19"
        />
        <img
          src={require("../../assets/gallery/20.jpg")}
          className="gallery-20 gallery-image"
          alt="gallery-20"
        />
        <img
          src={require("../../assets/gallery/21.png")}
          className="gallery-21 gallery-image"
          alt="gallery-21"
        />
        <img
          src={require("../../assets/gallery/22.gif")}
          className="gallery-22 gallery-image"
          alt="gallery-22"
        />
        <img
          src={require("../../assets/gallery/23.png")}
          className="gallery-23 gallery-image"
          alt="gallery-23"
        />
        <img
          src={require("../../assets/gallery/24.jpg")}
          className="gallery-24 gallery-image"
          alt="gallery-24"
        />
        <img
          src={require("../../assets/gallery/25.jpg")}
          className="gallery-25 gallery-image"
          alt="gallery-25"
        />
      </div>
    )
  }

  const renderActiveImage = () => {
    // get all images on the page
    const images = document.getElementsByClassName("gallery-image") as HTMLCollectionOf<HTMLImageElement>;
    if (activeImage === -1)
      return null;
    else
      return (
        <div 
          className="active-image-wrapper"
          onClick={() => setActiveImage(-1)}
        >
          <img
            alt={`gallery-${activeImage+1}`}
            className={`gallery${activeImage+1} active-image`}
            src={images[activeImage].src}
          />
        </div>
      )
  }

  return (
    <div className="gallery-page-wrapper page-wrapper">
      <span className="page-title">Gallery</span>
      {renderActiveImage()}
      {renderImages()}
    </div>
  )
}

export default GalleryPage;