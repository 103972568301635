import "./Navigation.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../context/AppDataContext";
import { APP_DATA_CONTEXT } from "../../utils/Interface";
import { Menu } from "react-feather";

const Navigation = () => {

  const links = [
    {
      title: "HOME",
      target: "/"
    },
    {
      title: "ABOUT",
      target: "/about"
    },
    {
      title: "PROCESS",
      target: "/process"
    },
    {
      title: "GALLERY",
      target: "/gallery"
    },
    {
      title: "COLLAGE",
      target: "/collage"
    },
    {
      title: "INSTAGRAM",
      target: "https://www.instagram.com/cainandabel.us"
    },
    {
      title: "CONTACT",
      target: "/contact"
    },
  ]

  const { navigationPosition, setNavigationPosition } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const [activePage, setActivePage] = useState<string>("HOME");
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getActivePage();
  // eslint-disable-next-line
  }, [navigate]);

  const getActivePage = () => {
    let path = window.location.pathname.substring(1);
    if (path === "") path = "HOME";
    setActivePage(path.toUpperCase());

    if (path === "HOME")
      setNavigationPosition("bottom-right");
    else 
      setNavigationPosition("top-right");
  }

  const isActivePage = (title: string) => {
    return title.toLowerCase() === activePage.toLowerCase();
  }

  const renderLinks = () => {
    return (
      links.map((link) => 
        <div 
          className={`link ${isActivePage(link.title) ? "active" : ""}`}
          onClick={() => setMenuOpen(false)}
        >
          <Link to={link.target} target={link.title === "INSTAGRAM" ? "_blank" : "_self"}>
            <span>{link.title}</span>
            <div className="link-background"></div>
          </Link>
        </div>
      )
    )
  }

  return (
    <div 
      className={`navigation-wrapper ${navigationPosition} ${menuOpen ? "open" : ""}`}
    >
      <span
        onClick={() => setMenuOpen(!menuOpen)}
        className="navigation-current-page"
      >
        {activePage}
      </span>
      {!menuOpen  && <Menu className="link-menu-button" onClick={() => setMenuOpen(true)}/> }
      <div className="link-wrapper">
        {renderLinks()}      
      </div>
    </div>
  )
}

export default Navigation;