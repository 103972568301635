import "./AboutPage.css";
import CarpenterPantsCloseUp from '../../assets/Carpenter Pants Close Up.png';
import HoodieFlat2 from '../../assets/Hoodie Flat 2.png';
import { Link } from "react-router-dom";

const AboutPage = () => {

  const renderImages = () => {
    return (
      <div className="images-wrapper">
        <img 
          alt="carpenter pants close up"
          className="carpenter-pants-close-up"
          src={CarpenterPantsCloseUp}
        />
        <img 
          alt="hoodie flat 2"
          className="hoodie-flat-2"
          src={HoodieFlat2}
        />
      </div>
    )
  }

  return (
    <div className="about-page-wrapper page-wrapper">
      {renderImages()}
      <div className="about-content-wrapper">
        <span className="page-title">ABOUT</span>
        <p>
          We are a fully vertical soft goods manufacturing company based in Brooklyn, New York. Operated by a small group of brand owners, we offer a very unique experience in premium clothing manufacturing.
        </p>
        <p>
          Our overseas factories specialize in full scale cut & sew garment production at quantities you aren’t going to find anywhere else in the world. We offer our clients exclusive access to a range of services and customization options typically only available to large-scale brands.
        </p>
        <p>
          In quantities as low as 36, we’re capable of creating fabric of any TPX color, weight, knit, finish, and style. With an endless array of branding options, we are fully equipped to cater to the diverse needs of any project.
        </p>
        <p>
          We offer a seamless process, providing expert guidance, frequent updates, visual progress reports, and constant communication. Send us your specifications and let us take care of bringing the product to life. If you are interested in working with us, please refer to our <Link to="/contact">Contact Page</Link>
        </p>
      </div>
    </div>
  )
}

export default AboutPage;