import { useState } from "react";
import CardiganLayout from "../../assets/Cardigan Layout.png";
import "./ContactPage.css";
import DrawCords from "../../assets/Drawcords.png";
import SnapButtonCloseUp from "../../assets/Snap Button Close Up.png";
import emailjs from "@emailjs/browser";

const ContactPage = () => {
  const [projectName, setProjectName] = useState<string>("");
  const [contactInfo, setContactInfo] = useState<string>("");
  const [introduction, setIntroduction] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("SUBMIT");

  const renderImages = () => {
    return (
      <div className="images-wrapper">
        <img
          alt="cardigan layout"
          className="cardigan-layout"
          src={CardiganLayout}
        />
        <img
          alt="snap button close up"
          className="snap-button-close-up"
          src={SnapButtonCloseUp}
        />
        <img alt="drawcords" className="drawcords" src={DrawCords} />
      </div>
    );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setButtonText("SENDING MESSAGE");
    emailjs
      .sendForm(
        "service_u7tx2yr",
        "template_ckyvz8i",
        e.target,
        "JXSwwNMiIp8EU3rIH"
      )
      .then(
        (result) => {
          // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
          console.log(result);
          setButtonText("MESSAGE SENT");
          setProjectName("");
          setContactInfo("");
          setIntroduction("");
        },
        (error) => {
          console.log(error.text);
          setButtonText("FAILED TO SEND MESSAGE");
        }
      );
  };

  const renderContactForm = () => {
    return (
      <form className="contact-form" onSubmit={onSubmit}>
        <span className="form-title">Tell us about your project</span>
        <section className="input-section">
          <span className="input-description">Project Name:</span>
          <input
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            onInput={() => setButtonText("SUBMIT")}
            className="input-field"
            name="project_name"
          />
        </section>
        <section className="input-section">
          <span className="input-description">Contact Info:</span>
          <input
            value={contactInfo}
            onChange={(e) => setContactInfo(e.target.value)}
            onInput={() => setButtonText("SUBMIT")}
            className="input-field"
            name="contact_info"
          />
        </section>
        <section className="input-section introduction-section">
          <span className="input-description">Brief Introduction:</span>
          <textarea
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            onInput={() => setButtonText("SUBMIT")}
            className="input-field"
            name="project_description"
          />
        </section>
        <button>{buttonText}</button>
      </form>
    );
  };

  return (
    <div className="contact-page-wrapper page-wrapper">
      <div className="content-wrapper">
        <span className="page-title">Contact</span>
        {renderContactForm()}
      </div>
      {renderImages()}
    </div>
  );
};

export default ContactPage;
